<template>
  <div :class="theme">
    <div style="padding: 20px">
      <h2>模型树更新日志</h2>
      <p>1.移动端模型树全新上线</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      theme: "light",
    };
  },
  mounted() {
    if (this.$route.params.theme == "light") {
      this.theme = "light";
      document.querySelector("body").removeAttribute("style");
    } else if (
      this.$route.params.theme == "dark" ||
      this.$root.config.theme.includes("dark")
    ) {
      this.theme = "dark";
      document
        .querySelector("body")
        .setAttribute("style", "background:#101218");
    }
  },
  beforeUnmount() {
    document.querySelector("body").removeAttribute("style");
  },
};
</script>
<style>
.dark {
  color: #ebebeb;
  background: #101218;
}
</style>
